<script>
import { Pie } from 'vue-chartjs'
import vueChart from 'components/charts/vueChart'
export default {
  mixins: [vueChart],
  extends: Pie,
}
</script>

<style lang="sass" type="text/sass" scoped></style>
