/**
 * 文件: https://vue-chartjs.org/guide/
 */
export default {
  props: {
    chartData: {
      type: Object,
      default: null
    },
    options: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    timeout: null,
  }),
  beforeDestroy() {
    this.clearTimeout()
  },
  mounted() {
    this.renderChart(this.chartData, this.options)
  },
  methods: {
    clearTimeout() {
      window.clearTimeout(this.timeout)
      this.timeout = null
    },
    reRenderAction() {
      this.clearTimeout()
      this.timeout = window.setTimeout(() => {
        this.renderChart(this.chartData, this.options)
      }, 500)
    },
  },
  watch: {
    chartData: {
      deep: true,
      handler() {
        this.reRenderAction()
      },
    }
  },
}